<template>
  <div class="teaching-data-card">
    <div class="card-head">
      <img class="head-icon" v-if="data.type==='pdf'" src="~@/assets/image/pdf-icon.png" />
      <img class="head-icon" v-else-if="data.type==='code'" src="~@/assets/image/code-icon.png" />
      <img class="head-icon" v-else src="~@/assets/image/video-icon.png" />
      <span class="head-text">{{data.title}}</span>
    </div>
    <div class="card-content">
      <template v-if="data.type !== 'code'">
      <video-player
        v-if="data.type !== 'pdf'"
        class="video-player vjs-custom-skin"
        ref="videoPlayer"
        :playsinline="true"
        @play="onPlayerPlay($event)"
        @fullscreenchange="onPlayerFullScreenchange($event)"
        @click="fullScreen"
        :options="playerOptions"
      >
      </video-player>
      <div v-else @click="showPdfFile">
        <img :src="coverUrl" style="cursor: pointer" />
      </div>
      </template>
      <el-link v-else
       type="primary"
        :href="codeUrlFilter(data.url)"
        target="_bank">查看源代码</el-link>
      <el-dialog
        v-show="data.type==='pdf'"
        width="60%"
        :title="null"
        :visible.sync="pdfVisible"
        :before-close="handleClose"
        :show-close="false"
        custom-class="dialog-pdf"
      >
        <div class="dialog-pdf-content">
          <pdf
            ref="pdf"
            :src="pdfUrl"
            :page="pageNo"
          ></pdf>
        </div>
        <div class="dialog-head-actions">
          <el-button size="mini" type="primary" :disabled="pageNo===1" @click="handlePreClick"><i class='el-icon-arrow-left'/>上一页</el-button>
          <el-button size="mini" type="primary" :disabled="pageNo===numPages" @click="handleNextClick"
            >下一页<i class='el-icon-arrow-right'/></el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import pdf from 'vue-pdf';
import VideoPlayer from "vue-video-player";
import "vue-video-player/src/custom-theme.css";
import "video.js/dist/video-js.css";
import hls from "videojs-contrib-hls";
Vue.use(hls);
Vue.use(VideoPlayer)
export default {
  name: 'TeachingCard',
  props: {
    data: Object,
    urlParam:Object
  },
  components: {
    pdf,
  },
  data() {
    return {
      pdfVisible: false,
      playerOptions: {
        height: '150',
        width: '200',
        autoplay: false,
        muted: true,
        language: 'zh-CN',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: 'video/mp4',
            src: '',
          },
        ],
        notSupportedMessage: '此视频暂时无法播放，请稍后再试',
        poster: '',
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },
      coverUrl: '',
      pdfUrl: '',
      numPages: 0,
      pageNo: 1
    };
  },
  created: function () {
    const data = this.data;
    if(data&&data.type!=='pdf'){
      let type = data.type!=='m3u8'?`video/${data.type}`:`application/x-mpegURL`;
      console.log(type,'type');
      this.playerOptions.sources = [
          {
            type,
            src: data.url,
          },
        ];
     this.playerOptions.poster =  data.cover;
    }else{
      this.pdfUrl =  data.url;
      this.coverUrl =  data.cover;
    }
  },
  mounted: function () {
   if(this.data&&this.data.type==='pdf'){
      this.getNumPages();
   }
  },
  methods: {
    codeUrlFilter(url){
      if(this.urlParam){
        return `${url}&auth=${this.urlParam.auth}&user_id=${this.urlParam.userId}&examAnswerId=${this.urlParam.examAnswerId}`;
      }
      return url;
    },
    handlePreClick() {
      document.getElementsByClassName('dialog-pdf-content')[0].scrollTop = 0;
      this.pageNo--;
    },
    handleNextClick() {
      document.getElementsByClassName('dialog-pdf-content')[0].scrollTop = 0;
      this.pageNo++;
    },
    showPdfFile() {
      this.pdfVisible = true;
    },
    getNumPages() {
      let loadingTask = pdf.createLoadingTask(this.pdfUrl);
      loadingTask.promise
        .then((pdf) => {
          this.numPages = pdf.numPages;
        })
        .catch((err) => {
          console.error('pdf 加载失败', err);
        });
    },
    handleClose() {
      this.pdfVisible = false;
    },
    onPlayerPlay(player) {
      // player.play();
      this.full(player);
    },
    //视频的播放、
    fullScreen() {
      const player = this.$refs.videoPlayer.player;
      player.requestFullscreen(); //调用全屏api方法
      player.isFullscreen(true);
      player.play();
    },
    full(element) {
      //做兼容处理
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      } else if (element.oRequestFullscreen) {
        element.oRequestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullScreen();
      } else {
        // var docHtml = document.documentElement;
        // var docBody = document.body;
        var videobox = document.getElementsByClassName('video-player');
        var cssText = 'width:100%;height:100%;overflow:hidden;';
        // docHtml.style.cssText = cssText;
        // docBody.style.cssText = cssText;
        videobox.style.cssText = cssText + ';' + 'margin:0px;padding:0px;';
        // document.IsFullScreen = true;
        videobox.IsFullScreen = true;
      }
    },
  },
};
</script>

<style lang="scss">
  .dialog-pdf{
    height: 80%;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body{
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
</style>
<style lang="scss" scoped>
  @import '@/assets/css/views/teaching.scss';
</style>