import { axios } from '@/utils/request'

export function courseTags(parameter) {
    return axios({
        url: '/v1/tags',
        method: 'get',
        params: parameter
    })
}

export function courses(parameter) {
    return axios({
        url: '/v1/courses',
        method: 'get',
        params: parameter
    })
}

export function courseDetail(parameter) {
    return axios({
        url: '/v1/course/detail',
        method: 'get',
        params: parameter
    })
}

// 课程单元详情
export function getCourseUnitDetail(parameter) {
    return axios({
        url: '/v1/course/unitDetail',
        method: 'get',
        params: parameter
    })
}

// 教学-课程引导步骤
export function getCourseGuide(parameter) {
    return axios({
        url: '/v1/courseUnit/guide/content',
        method: 'get',
        params: parameter
    })
}

// 教学-课程引导步骤
export function getCourseResource(parameter) {
    return axios({
        url: '/v1/courseUnit/guide/resource',
        method: 'get',
        params: parameter
    })
}

// 教学-练习题基本信息
export function getPracticeInfo(parameter) {
    return axios({
        url: '/v1/courseUnit/guide/exercise',
        method: 'get',
        params: parameter
    })
}

// 获取练习题列表
export function getPracticeList(id) {
    return axios({
        url: `/xzsstu/exam/paper/select/${id}`,
        method: 'post',
    })
}
// 提交练习题
export function submitAnswer(parameter) {
    return axios({
        url: '/xzsstu/exampaper/answer/answerSubmit',
        method: 'post',
        data: parameter
    })
}

//学生开始上课，获取上课时间
export function getCourseTime(parameter){
    return axios({
        url:'/teaching/user/getUserDetails/startTimes',
        method: 'post',
        data: parameter
    })
}

export function getCourseDetail(parameter){
    return axios({
        url:'/teaching/user/getUserDetails/getCourseDetails',
        method:'post',
        data:parameter
    })
}

export function sendStudyTimes(param){
    return axios({
        url:`/teaching/user/getUserDetails/sendStudyTimes?lesson_id=${param.lesson_id}&lesson_start_at=${param.lesson_start_at}&live_org_lesson_id=${param.live_org_lesson_id}&school_id=${param.school_id}`,
        method:'get'
    })
}